import React, { useState, useEffect } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonDatetime } from '@ionic/react';

const Home = () => {

    const [ time, setTime ] = useState( new Date() );

    useEffect(() => {
        var timerID = setInterval( () => tick(), 1000 );
        
        return function cleanup() {
            clearInterval(timerID);
            };
    });
      
    function tick() {
        setTime(new Date());
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonDatetime value={time.toString()} displayFormat='YYYY-MM-DD' slot='start'></IonDatetime>
                    <IonTitle>
                        
                        
                    </IonTitle>
                    <IonDatetime value={time.toString()} displayFormat='H:mm:ss' slot='end'></IonDatetime>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            </IonContent>
        </IonPage>
    );

}

export default Home;