import React from 'react';
import './App.css';

//ionic staff
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import Home from './pages/Home';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';



const App: React.FC = () => (
  
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" exact component={Home} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>

)

export default App;
